import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import Basket from '@components/shop/Basket';

import Alert from '@components/_ui/Alert';
import Icon from '@components/_ui/Icon';
import checkoutService from '@data/services/checkout.service';
import BillingAddressFields from './input-groups/BillingAddressFields';
import ShippingAddressFields from './input-groups/ShippingAddressFields';
import PaymentDetailFields from './input-groups/PaymentDetailFields';


// NOTE: ALL THE AJAX REQUEST STUFF IS DEALT WITH IN THE PAYMENT DETAILS FIELDS AS ITS TRIGGERED BY WORLDPAY
const CheckoutForm = () => {
    const [submitTryCount, setSubmitTryCount] = useState(0);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [billingDetails, setBillingDetails] = useState({});

    /** When the form is submitted, html validation is run and then world pay is submitted. */
    const submitCheckout = (e) => {
        e.preventDefault();
        setSubmitTryCount((s) => s + 1);

        if (window.Worldpay) {
            // this does the ajax call too, as we need a call back from worldpay
            // NOTE: ALL THE AJAX REQUEST STUFF IS DEALT WITH IN THE PAYMENT DETAILS FIELDS AS ITS TRIGGERED BY WORLDPAY
            window.Worldpay.submitTemplateForm() // eslint-disable-line
        } else {
            alert('cannot purchase, there is a problem with the payment gateway');
        }
    };

    /**
     * on card details confirmation - get token and ajax submit the form
     * @param {formelement} form
     */
    const onWorldPayCallback = async (form) => {
        setIsLoading(true);

        let response;
        /** After submit of worldpay iframe form. */
        const formData = new FormData(form);
        try {
            response = await checkoutService.submitCheckout(formData, form.action);
        } catch (err) {
            setIsLoading(false);
        }
        const returnedData = response ? response.data : null;

        if (returnedData && returnedData.threeds_jwt) {
            window.location.href = `/shop/three-d-s/redirect-form?token=${returnedData.threeds_jwt}`;

        /** If success redirect. Otherwise, remove the token from the form, and show an error. */
        } else if (returnedData && returnedData.success) {
            window.location.href = '/shop/payment-confirmed';
        } else {
            const token = form.querySelector('input[name="token"]');
            token.parentNode.removeChild(token);

            setError(returnedData && returnedData.reason ? returnedData.reason : 'There was an unknown error, please try again or contact us.');
            setIsLoading(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className="container">

            <Helmet>
                {/*
                    We have this in the layout file too but the opposite, once the worldpay file has loaded we want it consistently in the header
                    but we dont want it in the header until its loaded for the first time here.
                */}
                {(typeof window !== 'undefined' && !window.Worldpay) ? <script src="https://cdn.worldpay.com/v1/worldpay.js" /> : null}
            </Helmet>

            {/* The id is used to submit the form in worldpay */}
            <form
                action="/.netlify/functions/payment"
                id="checkoutForm"
                className={`grid-x grid-margin-x grid-margin-y ${submitTryCount > 0 ? '_js_form-submit-tried' : ''}`}
                onSubmit={submitCheckout}
            >

                { error && (
                    <section className="cell small-12">
                        <Alert type="error">
                            <span className="text-label">Payment failed</span>
                            <p className="text-paragraph text-paragraph--small">{error || 'there is an error on the site'}</p>
                        </Alert>
                    </section>
                )}
                <div className="cell large-7 flow--large">
                    <BillingAddressFields billingDetails={billingDetails} setBillingDetails={setBillingDetails} />
                    <ShippingAddressFields billingDetails={billingDetails} />
                    <PaymentDetailFields onWorldPayCallback={onWorldPayCallback} />
                </div>
                <aside className="cell large-5 flow--large">
                    <Basket error={error} />
                </aside>
                <div className="cell">
                    <button
                        type="submit"
                        className="button button__justify button--large checkout__submit"
                        disabled={isLoading}
                        onClick={() => { setSubmitTryCount((state) => state + 1); }}
                    >
                        <span>{isLoading ? 'Submitting... ' : 'Order & Pay'}</span>
                        <Icon id="arrow-right" className="button__icon" />
                    </button>
                </div>
            </form>
        </div>


    );
};

export default CheckoutForm;
