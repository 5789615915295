import React from 'react';

import Layout from '@layouts/Layout';

import Checkpoints from '@components/shop/Checkpoints';
import CheckoutForm from '@components/shop/CheckoutForm/CheckoutForm';
import { BasketProvider } from '../../data/context/BasketContext';


/** Checkout Page */
const Checkout = () => (
    <Layout mainClassName="flow--xlarge" metaData={{ title: 'Checkout Page' }}>

        <header className="hero hero--has-checkpoints">
            <div className="container flow">
                <Checkpoints />
                <h1 className="text-subtitle text-align--center">Checkout</h1>
            </div>
        </header>

        <section className="wrapper background-color--chalk flow--medium">
            <BasketProvider>
                <CheckoutForm />
            </BasketProvider>
        </section>
    </Layout>
);

export default Checkout;
