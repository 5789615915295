import { useEffect } from 'react';

/**
 * This sets up a loop to see when world pay is initiated, the call back is run after the card details have been submit
 * @param {*} divId - the div that the worldpay iframe goes into
 * @param {*} formId - the form that the token input gets appended to.
 * @param {*} afterTokenInsert - the callback that is run after the token has been added
 */
const useWorldPayForm = (divId, formId, afterTokenInsert) => {
    useEffect(() => { // eslint-disable-line
        if (typeof window !== 'undefined') {
            let timeout = null;

            console.log(process.env.GATSBY_WORLD_PAY_CLIENT_KEY);

            const initWorldPay = () => {
                if(window.Worldpay) { // eslint-disable-line
                    window.Worldpay.useTemplateForm({ // eslint-disable-line
                        clientKey: `${process.env.GATSBY_WORLD_PAY_CLIENT_KEY}`,
                        form: 'payment_form', // name of form on worldpay's templated
                        paymentSection: divId,
                        display: 'inline',
                        reusable: false,
                        saveButton: false,
                        callback(obj) {
                            if (obj && obj.token) {
                                const el = document.createElement('input');
                                el.value = obj.token;
                                el.type = 'hidden';
                                el.name = 'token';
                                document.getElementById(formId).appendChild(el);
                                afterTokenInsert(document.getElementById(formId));
                            }
                        }
                    });
                    window.clearTimeout(timeout);
                }
            };

            timeout = setTimeout(initWorldPay, 500);

            return () => {
                window.Worldpay = null;
                clearTimeout(timeout);
            };
        }
    }, []);
};

export default useWorldPayForm;
