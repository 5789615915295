import React, { Fragment, useState } from 'react';

import TextInput from '@components/forms/TextInput';
import CheckboxInput from '@components/forms/CheckboxInput';

const ShippingAddressFields = ({ billingDetails }) => {
    const [same, setSame] = useState(true);
    return (
        <Fragment>
            <h2 className="sep-heading">
                <span className="counter counter--white">2</span>
                <span className="text-heading">Shipping address</span>
                <hr className="sep sep--light" />
            </h2>
            <fieldset className="form-section__fields flow">
                <CheckboxInput
                    name="same-shipping-address"
                    label="Shipping address is the same as Billing address"
                    checked={same}
                    onChange={(e) => setSame(e.target.checked)}
                />
                {!same
                    ? (
                        <Fragment>
                            <div className="flow--small">
                                <TextInput
                                    name="shipping_address_one"
                                    placeholder="Address line 1"
                                    label="Address*"
                                    className="form-field--address"
                                    required
                                />
                                <TextInput
                                    name="shipping_address_two"
                                    placeholder="Address line 2"
                                    label="Address 2"
                                    hideLabel
                                    className="form-field--address"
                                />
                            </div>
                            <TextInput
                                name="shipping_city"
                                placeholder="Town or city, county"
                                label="City*"
                                hideLabel
                                className="form-field--address"
                                required
                            />
                            <TextInput
                                name="shipping_postcode"
                                placeholder="e.g. AB12 3CD"
                                label="Postcode*"
                                className="form-field--postcode"
                                required
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <input
                                name="shipping_address_one"
                                type="hidden"
                                value={billingDetails.billing_address_one || ''}
                            />
                            <input
                                name="shipping_address_two"
                                type="hidden"
                                value={billingDetails.billing_address_two || ''}
                            />
                            <input
                                name="shipping_city"
                                type="hidden"
                                value={billingDetails.billing_city || ''}
                            />
                            <input
                                name="shipping_postcode"
                                type="hidden"
                                value={billingDetails.billing_postcode || ''}
                            />
                        </Fragment>
                    )}
            </fieldset>
        </Fragment>
    );
};

export default ShippingAddressFields;
