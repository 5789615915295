import React, { Fragment } from 'react';

import useWorldPayForm from '@hooks/useWorldPayForm';

const PaymentDetailFields = ({ onWorldPayCallback }) => {
    /** Worldpay initiated. The provided call back is run after the card details have been submit */
    useWorldPayForm('paymentSection', 'checkoutForm', onWorldPayCallback); // hook to init iframe

    return (
        <Fragment>
            <h2 className="sep-heading">
                <span className="counter counter--white">3</span>
                <span className="text-heading">Payment details</span>
                <hr className="sep sep--light" />
            </h2>
            <fieldset className="form-section__fields flow">
                <div id="paymentSection" className="paymentSection" />
            </fieldset>
        </Fragment>
    );
};

export default PaymentDetailFields;
