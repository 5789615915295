import React from 'react';

import Icon from '@components/_ui/Icon';

const CheckboxInput = ({
    name, label, className, onChange, checked
}) => (
    <div className={`form-field form-checkbox ${className}`}>
        <input id={name} name={name} type="checkbox" className="form-checkbox__input" onChange={onChange} checked={checked} />
        <label htmlFor={name} className="form-checkbox__label text-paragraph">
            <span>{label}</span>
            <span className="form-checkbox__pseudo">
                <Icon id="check" className="form-checkbox__check" />
            </span>
        </label>

    </div>
);

CheckboxInput.defaultProps = {
    name: '',
    label: '',
    className: '',
};

export default CheckboxInput;
