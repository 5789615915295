import React, { Fragment } from 'react';

import TextInput from '@components/forms/TextInput';
import EmailInput from '@components/forms/EmailInput';
import TelephoneInput from '@components/forms/TelephoneInput';


const BillingAddressFields = ({
    billingDetails,
    setBillingDetails
}) => (
    <Fragment>

        <h2 className="sep-heading">
            <span className="counter counter--white">1</span>
            <span className="text-heading">Billing address</span>
            <hr className="sep sep--light" />
        </h2>
        <fieldset className="form-section__fields flow">
            <TextInput required name="name" placeholder="i.e. John David Doe" label="Name*" className="form-field--name" />

            <div className="flow--small">
                <TextInput
                    required
                    name="billing_address_one"
                    placeholder="Address line 1"
                    label="Address*"
                    className="form-field--address"
                    value={billingDetails.billing_address_one}
                    onChange={(e) => { e.persist(); setBillingDetails((state) => ({ ...state, [e.target.name]: e.target.value })); }}
                />
                <TextInput
                    name="billing_address_two"
                    placeholder="Address line 2"
                    label="Address 2"
                    hideLabel
                    className="form-field--address"
                    onChange={(e) => { e.persist(); setBillingDetails((state) => ({ ...state, [e.target.name]: e.target.value })); }}
                />
            </div>
            <TextInput
                required
                name="billing_city"
                placeholder="e.g. London"
                label="City*"
                className="form-field--postcode"
                onChange={(e) => { e.persist(); setBillingDetails((state) => ({ ...state, [e.target.name]: e.target.value })); }}
            />
            <TextInput
                required
                name="billing_postcode"
                placeholder="e.g. AB12 3CD"
                label="Postcode*"
                className="form-field--postcode"
                onChange={(e) => { e.persist(); setBillingDetails((state) => ({ ...state, [e.target.name]: e.target.value })); }}
            />
            <div className="cluster--tight">
                <div className="form-row">
                    <EmailInput
                        name="email"
                        placeholder="e.g. hello@email.com"
                        label="Email address*"
                        className="form-field--email"
                        required
                    />
                    <TelephoneInput name="telephone" placeholder="e.g. 01234 567 890" label="Telephone" className="form-field--telephone" />
                </div>
            </div>
        </fieldset>
    </Fragment>
);


export default BillingAddressFields;
