import React, { useState } from 'react';

import Icon from '@components/_ui/Icon';
import { useBasketContext } from '../../data/context/BasketContext';
import toTitleCase from '../../utils/toTitleCase';

const Basket = () => {
    const { basket } = useBasketContext();
    const [basketOpen, setBasketOpen] = useState(false);

    const handleToggleBasket = (e) => {
        e.preventDefault();
        if (basketOpen) {
            setBasketOpen(false);
        } else {
            setBasketOpen(true);
        }
    };

    return basket.module && (
        <div id="basket-open" className={`basket${(basketOpen) ? ' _is-open' : ''}`}>
            <a href="#basket-open" className="basket__header" onClick={handleToggleBasket} title="Open basket">
                <h4 className="text-subheading">Your basket</h4>
                <Icon id="chevron-down" className="basket__chevron" />
            </a>
            <hr className="sep sep--light" />
            <div className="basket__summary flow--small">
                <div className="flow--xsmall">
                    <img src={basket.module.imageUrl} alt={basket.module.title} className="module-card__image" />
                    <input type="hidden" name="moduleImageUrl" value={basket.module.imageUrl} />
                    <div className="flex--justify">

                        <h4 className="text-subheading">{basket.module.title}</h4>
                        <span className="text-price">£{basket.module.price}</span>
                    </div>
                    <p className="text-metadata">{basket.module.description}</p>
                </div>
                <hr className="sep sep--dashed" />
                <div className="bracket breathe flex--align-center flex--justify">
                    <Icon id="wood" className="bracket__fixed icon--large" />
                    <span className="text-metadata bracket__flex">{basket.woodType}</span>
                    <span className="text-price">£{Math.abs(basket.module.price - basket.calculatedPrice)}</span>
                </div>
                <hr className="sep sep--dashed" />
                <div className="bracket breathe flex--align-center flex--justify">
                    <Icon id="spike-fixing" className="bracket__fixed icon--large" />
                    <span className="text-metadata bracket__flex">{basket.fixingType} fixing</span>
                    <span className="text-price">£0</span>
                </div>
            </div>
            <hr className="sep sep--light" />

            <div className="flex--justify">
                <span className="text-label">Total</span>
                <span className="text-price">£{basket.calculatedPrice}</span>
            </div>

            <input type="hidden" name="amount" value={basket.calculatedPrice} />
            <input type="hidden" name="order_description" value={`${basket.module.title} - ${basket.module.description} in ${toTitleCase(basket.woodType)}, with ${toTitleCase(basket.fixingType)} Fixing`} />
            <input type="hidden" name="basket_session_id" value={basket.basketSessionId} />
        </div>
    );
};

export default Basket;
